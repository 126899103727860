<template>
    <el-form-item label="单据状态">
        <el-select placeholder="单据状态" v-model="status" filterable>
            <el-option label="请选择" value="" />
            <el-option v-for="item in arr" :label="item.desc" :value="item.status" :key="item.status" />
        </el-select>
    </el-form-item>
</template>

<script>
import StockBizCommon from 'js/StockBizCommon';

export default {
    name: 'EfStockBizStatus',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: [Number, String],
            default: '',
        },
    },
    data() {
        return {
            status: '',
            arr: [],
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.status = n;
            },
            immediate: true,
        },
        status: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
    mounted() {
        this.arr = StockBizCommon.replenisStatus2Desc();
    },
};
</script>
