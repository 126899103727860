<template>
    <div class="Replenishment">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="6">
                        <ef-biz-code v-model="form.code" />
                    </el-col>
                    <el-col :span="18">
                        <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    </el-col>
                    <el-col :span="6">
                        <ef-replenish-status v-model="form.repayDeptGoodsStatus" />
                    </el-col>
                    <el-col :span="6">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="6">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.open')"
            >
                查询
            </el-button>
            <!--            <el-button-->
            <!--                type="primary"-->
            <!--                @click="handleCreate"-->
            <!--                size="small"-->
            <!--                v-if="hasPrivilege('menu.mall.manage.repay.goods.create')"-->
            <!--                >新建-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--                type="primary"-->
            <!--                @click="showReviewDialog"-->
            <!--                size="small"-->
            <!--                v-if="hasPrivilege('menu.mall.manage.repay.goods.review')"-->
            <!--                >审核-->
            <!--            </el-button>-->
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.repay.goods.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                id="printMe"
                :data="tableData"
                ref="table"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="单据号" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="补货机构" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="单据状态"
                    width="100"
                    prop="repayDeptGoodsStatus"
                    key="repayDeptGoodsStatus"
                    v-if="showColumn('repayDeptGoodsStatus') && showInPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.repayDeptGoodsStatus | repayDeptGoodsStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="实际补货数量"
                    width="160"
                    prop="totalRepayGoodsCount"
                    v-if="showColumn('totalRepayGoodsCount')"
                />
                <el-table-column
                    label="入库时间"
                    width="160"
                    prop="repayGoodsTime"
                    v-if="showColumn('repayGoodsTime')"
                />
                <el-table-column
                    label="入库操作人"
                    width="120"
                    prop="repayOperatorName"
                    v-if="showColumn('repayOperatorName')"
                />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看 </el-button>
                        <!--                        <el-button-->
                        <!--                            size="mini"-->
                        <!--                            type="warning"-->
                        <!--                            :disabled="scope.row.reviewStatus == 2"-->
                        <!--                            @click="rowEdit(scope.row)"-->
                        <!--                            v-if="hasPrivilege('menu.mall.manage.repay.goods.edit')"-->
                        <!--                        >-->
                        <!--                            编辑-->
                        <!--                        </el-button>-->
                        <el-button
                            size="mini"
                            type="success"
                            @click="changeSingleBizStock(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.repay.goods.stock')"
                            :disabled="scope.row.repayDeptGoodsStatus == 2"
                            >入库
                        </el-button>
                        <!--                        <el-button-->
                        <!--                            size="mini"-->
                        <!--                            type="danger"-->
                        <!--                            @click="handleDelete(scope.row)"-->
                        <!--                            v-if="hasPrivilege('menu.mall.manage.repay.goods.delete')"-->
                        <!--                            >删除-->
                        <!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
    </div>
</template>

<script>
import Util from 'js/Util';
import EfBizCode from 'components/EfBizCode';
import StockBizCommon from 'js/StockBizCommon';
import EfReplenishStatus from 'components/EfStockReplenishStatus';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';

export default {
    name: 'Replenishment',
    components: {
        EfReviewBiz,
        EfPagination,
        EfEndDate,
        EfStartDate,
        EfReplenishStatus,
        EfBizCode,
        CheckTableShowColumnDialog,
        EfDeptTypeAndDeptSelect,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            selectedBizArr: [],
            baseUrl: this.$efApi.replenishmentApi.baseUrl,
            form: {
                code: null,
                deptCode: null,
                endCreateTime: '',
                limit: 100,
                page: 1,
                repayDeptGoodsStatus: '',
                reviewStatus: '',
                endTime: Util.nowFormatDate(),
                abstractCode: '',
                startCreateTime: '',
            },
            status: '',
            total: 0,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            tableData: [],
            reArr: [], //补货机构
        };
    },
    computed: {
        //禁用/启用按钮
        disableReviewBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableReviewBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableReviewBtn;
            });
            return disableReviewBtnFlag >= 0;
        },
        disableChangeStockBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableChangeStockBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableChangeStockBtn;
            });
            return disableChangeStockBtnFlag >= 0;
        },
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        changeMultipleBizStock() {
            const codes = this.selectedBizArr.map((e) => {
                return e.code;
            });
            this.changeStock(codes);
        },
        changeSingleBizStock(row) {
            this.changeStock([row.code]);
        },
        changeStock(codes) {
            const _this = this;
            UrlUtils.PostRemote(
                _this,
                `/repayDeptGoods/repayGoods/${codes}`,
                {
                    codes: codes,
                    message: '确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!',
                },
                null,
                () => {
                    this.$message.success('入库成功');
                    this.handleQuery();
                }
            );
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },

        handleCreate() {
            Util.nameJump(this, 'menu.mall.manage.repay.goods.create', ['电商管理', '门店补货管理', '新建补货单']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.mall.manage.repay.good.detail', ['电商管理', '门店补货管理', '补货单详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.mall.manage.repay.goods.edit', ['电商管理', '门店补货管理', '编辑补货单'], {
                form: row,
                code: row.code,
            });
        },

        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            if (this.$refs.table.selection.length == 0) {
                this.$message.warning('请至少勾选一项导出');
                return;
            }
            UrlUtils.Export(this, '门店补货管理', '/repayDeptGoods/exportExtend', this.form, codes);
        },

        async queryList() {
            const rst = await this.$efApi.replenishmentApi.page(this.form);
            this.tableData = rst.list.map((e) => {
                return {
                    ...e,
                    wholeSaleMoneySum: 0,
                    //根据状态设置操作按钮禁用或启用
                    disableEditBtn: !StockBizCommon.validateCanEdit(e.reviewStatus),
                    disableDeleteBtn: !StockBizCommon.validateCanDelete(e.reviewStatus),
                    disableReviewBtn: !StockBizCommon.validateCanReview(e.reviewStatus),
                    disableChangeStockBtn: !StockBizCommon.validateCanChangeStock(e.reviewStatus, e.stockStatus),
                };
            });
            this.total = rst.count;
            // for (let i = 0; i < this.tableData.length; i++) {
            // const bizDetail = this.tableData[i];
            //根据code查询单据商品的批发价和数量并进行汇总显示
            // this.$efApi.replenishmentApi.detail(bizDetail.code).then((e = {}) => {
            //     let wholeSaleMoneySum = 0;
            //     for (let j = 0; j < e.wholeSalePrices.length; j++) {
            //         //批发单价*数量并累加
            //         wholeSaleMoneySum += (e.wholeSalePrices[j] || 0) * (e.counts[j] || 0);
            //     }
            //     bizDetail.wholeSaleMoneySum = wholeSaleMoneySum;
            // });
            // }
        },
        async handleDelete(row) {
            await this.$efApi.replenishmentApi.deleteBiz(row.code);
            this.handleQuery();
        },
    },
};
</script>

<style scoped>
.Replenishment .el-form-item {
    margin-bottom: 0;
}
</style>
